<!-- 海报(卡片)有两种查看方式，'/poster'页面 及 课程或专题相关页面内的全屏弹窗 -->
<!-- 本组件即 /poster 页面 -->
<template>
  <div class="page-poster">
    <!-- 侧边栏 -->
    <p class="tips">
      <Icon :_type="'info'" :_size="15" :_color="'#a5a5a5'"></Icon>
      如无法保存，请关闭无痕模式，或更换浏览器进行下载
    </p>
    <div class="operation-btn mob" :class="isSpread?'isspread':''" @click="changeSpread">
      <span>设置</span>
    </div>
    <div class="operation-area" :class="isSpread?'isspread':''">
      <div class="operation-con">
        <div class="close-icon mob" @click.prevent="changeSpread">
          <Icon :_type="'close'" :_size="16" :_color="'#ffffff'"></Icon>
        </div>
        <h1>设置：</h1>
        <ul>
          <li data-desc="年份">
            <label ><span>*</span>选择年份：</label>
            <div class="operation-menus">
              <el-dropdown class="operation-menu act" trigger="click">
                <span class="menu-item">
                  {{ selectParams.year && selectParams.year.slice(0,4) }}{{ selectParams.year && id_arr[selectParams.year] && id_arr[selectParams.year].address }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="operation-menu-year">
                  <el-dropdown-item v-for="(item,index) in Object.values(id_arr).sort(global.toSortKey('sid',0,'string'))" :key="'+'+index">
                    <!-- <p @click.prevent="selectYear(item.sid)">{{ item.year }} {{ item.address }}</p> -->
                    <a :href="'/poster?sid='+item.sid" target="_self">
                      <p>{{ item.year }} {{ item.address }}</p>
                    </a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </li>
          <li data-desc="类型">
            <label ><span>*</span>选择类型：</label>
            <div class="operation-menus">
              <div class="operation-menu" :class="(selectParams.type==key)?'act':''" v-for="(item,key) in baseType" :key="'+'+key">
                <span class="menu-item" @click="selectType(key)">{{ item[0] }}</span>
              </div>
            </div>
          </li>
          <li data-desc="选项" v-if="selectParams.cardId && selectParams.type!=='case'">
            <slot v-if="selectParams.type && baseType[selectParams.type] && baseType[selectParams.type][1]">
              <label v-if="baseType[selectParams.type][1].includes('设置')"><span>*</span>{{ baseType[selectParams.type][1] }}：</label>
              <label v-else><span>*</span>选择{{ baseType[selectParams.type][1] }}：
                <slot v-if="checkAllIn(selectParams.type)">
                    <br><br>
                    <div class="operation-menus" data-desc="批量生成">
                      <div class="operation-menu" :class="isAllIn&&!(indexsAllIn[1])?'act':''" @click="selectAllIn(selectParams.type)">批量截图</div>
                      <slot v-if="dataList[selectParams.type] && dataList[selectParams.type].length > lenLimit">
                        <div
                          v-for="num in Math.ceil(dataList[selectParams.type].length / lenLimit)"
                          :class="`operation-menu ${((indexsAllIn[0]==(num - 1) * lenLimit)&&(indexsAllIn[1]==Math.min(num * lenLimit, dataList[selectParams.type].length)))?'act':''}`"
                          @click="selectAllIn(selectParams.type, [(num - 1) * lenLimit, Math.min(num * lenLimit, dataList[selectParams.type].length)])"
                          :key="'menu_'+selectParams.type+num"
                        ><!-- {{ [(num - 1) * lenLimit, Math.min(num * lenLimit, dataList[selectParams.type].length)] }} --><i>{{
                          global.formatNumber((num - 1) * lenLimit + 1) }}</i><i
                            :style="(Math.min(num * lenLimit, dataList[selectParams.type].length)) > ((num - 1) * lenLimit + 1) ? '' : 'opacity:0;'"
                          >-{{
                          global.formatNumber(Math.min(num * lenLimit, dataList[selectParams.type].length))
                        }}</i>截图</div>
                      </slot>
                    </div>
                  </slot>
              </label>
            </slot>
            <div class="operation-menus" v-if="!((Array.isArray(_datalist[selectParams.type]) && _datalist[selectParams.type].length>0) || global.isObjectKeys(_datalist[selectParams.type]))">
              <p class="operation-tips">加载中...</p>
            </div>
            <slot v-else>
              <div class="operation-menus" v-if="['chairman','producer'].includes(selectParams.type)">
                <div class="operation-menu" :class="checkId(item.id)?'act':''" v-for="(item,index) in _datalist[selectParams.type]" :key="selectParams.type+'_'+index">
                  <span class="menu-item" @click="selectId(item.id)">{{ global.formatNumber(index+1) }}.{{ item.penName || item.name }}</span>
                </div>
              </div>
              <div class="operation-menus select-menus" v-if="selectParams.type==='subject'">
                <div class="operation-menu" :class="checkId(item.id)?'act':''" v-for="(item,index) in _datalist['subject']" :key="'s'+index" :style="(item.venueName&&item.venueName.includes('开幕式')?'opacity:0.6;':'')">
                  <span class="menu-item" @click="selectId(item.id)">{{ global.formatNumber(index+1) }}.{{ item.venueName }}</span>
                </div>
              </div>
              <div class="operation-menus select-menus" v-else-if="selectParams.type==='course'">
                <div class="operation-menu" :class="checkId(item.courseid, item.teacher&&item.teacher[0]&&item.teacher[0].id)?'act':''" v-for="(item,index) in _datalist['course']" :key="'c'+index">
                  <span class="menu-item" @click="selectId(item.courseid, {'clid':item.teacher&&item.teacher[0]&&item.teacher[0].id})">{{ global.formatNumber(index+1) }}.{{ (item.teacher&&item.teacher[0]&&item.teacher[0].name&&item.teacher[0].name+' -') || '' }} {{ item.title }}</span>
                </div>
              </div>
              <div class="operation-menus" v-if="selectParams.type==='schedule'">
                <div class="operation-menu" :class="checkId(index)?'act':''" v-for="(item,key,index) in _datalist['schedule']" :key="'sc'+key">
                  <span class="menu-item" @click="selectId(parseInt(key))">{{ '第'+(parseInt(key)+1)+'天' }}</span>
                </div>
                <p class="operation-tips">日程宣传图较长，故截图生成时间可能较长，<br>请耐心等候...</p>
              </div>
            </slot>
          </li>
          <slot v-if="!selectParams.cardId">
            <invite-setting data-desc="无模板" v-if="selectParams.type === 'invite'" @setinvite="setInvite"></invite-setting>
            <li data-desc="无模板" v-else>
              <label style="opacity:0;"><span>*</span>选择{{ selectParams.type && baseType[selectParams.type] && baseType[selectParams.type][1] }}：</label>
              <div class="operation-menus">
                <p class="operation-tips">当前类型暂无模板</p>
              </div>
            </li>
          </slot>
        </ul>
      </div>
    </div>
    <!-- 海报(卡片)预览 -->
    <Pages v-if="isAllIn"
      :_indexlist="indexsAllIn"
      :_paramlist="paramsAllIn"
      :_datalist="dataAllIn"
      @setinfo="setInfo" 
      :key="indexsAllIn[0]+'_'+(indexsAllIn[1]||'')+'_'+(paramsAllIn.id&&paramsAllIn.id.join('_'))"
    ></Pages>
    <slot v-else-if="selectParams.type && selectParams.type.length>0">
      <slot v-if="noCardType.includes(selectParams.type)">
        <Invite
          v-if="selectParams.type==='invite'&&(global.isObjectKeys(inviteFile)||global.isArrayIndexs(inviteText))"
          :_file="global.deepClone(inviteFile)"
          :_text="global.deepClone(inviteText)"
          :_group="inviteGroup"
          :key="inviteFile.url||''"
        ></Invite>
      </slot>
      <Page 
        v-else-if="isActive" 
        :_selectparams="JSON.stringify(selectParams)" 
        :_info="JSON.stringify(dataActive)" 
        :_origininfo="_origindata" 
        @setinfo="setInfo" 
        :key="`${selectParams.sid}_${selectParams.type}_${selectParams.cardId}_${selectParams.cardDetailId||''}_${selectParams.venueId||''}_${selectParams.courseId||''}`" 
      ></Page>
    </slot>
  </div>
</template>

<script>
import mixinPosterBase from "../utils/mixinPosterBase.js"
import { id_arr, sid, getSueType, getSueArr, getCardInfo, getSpeechInfoNew, getAllCourse, getScheduleInfo, scheduledifference, scheduleDifferenceId } from '../utils/api.js';

export default {
  name: 'Poster',
  mixins: [ mixinPosterBase ],
  props: { 
    _cardids: {
      type: Object,
      default: () => {}
    },
    _datalist: {
      type: Object,
      default: () => {}
    },
    _origindata: {
      type: [Object, Array],
      default: () => {}
    },
    _selectparams: [Object, String],
  },
  components: {
    Icon: ()=>import('../components/Icon.vue'),
    Page: () => import('../components/Card/Page.vue'),
    Pages: () => import('../components/Card/Pages.vue'),
    Invite: () => import('../components/Card/Invite.vue'),
    InviteSetting: () => import('../components/Card/InviteSetting.vue'),
  },
  data() {
    let _this = this;
    return {
      id_arr: id_arr,
      dataList: {},
      dataActive: {},
      selectParams: {
        year: false,
        sid: false,
        type: false,
        cardId: false,
        cardDetailId: false,
        venueId: false,
        courseId: false,
        scheduleId: false,
        lecturerId: false
      },
      inviteGroup: 1,
      inviteFile: {},
      inviteText: [],
      isSpread: false,
      showCard: true,
      isActive: false,
      // 批量下载
      isAllIn: false,
      lenLimit: 9,
      indexsAllIn: [], // 批量下载的索引数组,[起始索引,结束索引+1]
      paramsAllIn: { sid: false, type: false, cardid: false, id: [], year: false },
      dataAllIn: {},
    }
  },
  watch: {
    _datalist: {
      handler(newV, oldV) {
        // console.log('Poster·watch·_datalist ', newV, oldV);
        if(newV && typeof newV==='object') {
          this.dataList = this.global.deepClone(newV);
        }
      },
      immediate: true,
      deep: true,
    },
    _selectparams: {
      handler(newV, oldV) {
        // console.log('Poster·watch·_selectparams ', newV, oldV);
        if(newV && typeof newV==='string' && newV.includes('{') && newV !== oldV) {
          this.selectParams = JSON.parse(newV);
        }
      },
      immediate: true,
      deep: true,
    },
    selectParams: {
      handler(newV, oldV) {
        // console.log('Poster·watch·selectParams ', newV, oldV);
        if(newV) {
          if (!oldV || (typeof newV==='object' && typeof oldV==='object' && !(
            this.global.toCompareObject(newV,oldV) // detailid 和 id 分开判断，优先判断detailid 》》》》》》poster
          ))) {
            this.isActive = false;
            // if(newV.type=='schedule' && oldV && (newV.cardId==oldV.cardId)) {
            //   this.isActive = true;
            // } else {
            //   this.dataActive = {};
            //   this.getActiveData(newV);
            // }
            this.dataActive = {};
            this.getActiveData(newV);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    isActive: {
      handler(newV, oldV) {
        // console.log('Poster·watch·is_Active ', newV, oldV);
        if (newV) { // 为真时dataActive不为空,可返回
          this.$emit('dataactive', this.dataActive);
        }
      },
      immediate: true,
      deep: true,
    },
    // dataActive: {
    //   handler(newV, oldV) {
    //     // console.log('Poster·watch·dataActive ', newV, oldV);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  created() {
    this.resetAllIn()
  },
  methods: {
    // 专题/课程/案例/日程 active
    checkId(id, secondaryId) {
      // console.log(this.selectParams.type, '-', this.selectParams.venueId, '-', this.selectParams.courseId, '-', id);
      let flag = false;
      // secondaryId
      let sKey = this.selectParams.type && this.secondaryKeys[this.selectParams.type];
      if(this.selectParams.type == 'chairman' || this.selectParams.type == 'producer') {
        if(this.selectParams.lecturerId == id) {
          if(this.selectParams.lecturerId == id) {
            flag = true;
          }
        } else {
          if(this.dataActive && this.dataActive.lecturer && this.dataActive.lecturer.lecturerId == id) {
            flag = true;
          }
        }
      }
      if(this.selectParams.type == 'subject') {
        if(this.selectParams.venueId) {
          if(this.selectParams.venueId == id) {
            flag = true;
          }
        } else {
          if(this.dataActive && this.dataActive.venue && this.dataActive.venue.svId == id) {
            flag = true;
          }
        }
      }
      if(this.selectParams.type == 'course') {
        if(this.selectParams.courseId) {
          if(this.selectParams.courseId == id) {
            if (!sKey || !this.selectParams[sKey] || (secondaryId && this.selectParams[sKey]==secondaryId)) {
              flag = true;
            }
          }
        } else {
          if(this.dataActive && this.dataActive.course && this.dataActive.course.courseId == id) {
            flag = true;
          }
        }
      }
      if(this.selectParams.type == 'schedule') {
        if(this.selectParams.scheduleId) {
          if(this.selectParams.scheduleId!==false && this.selectParams.scheduleId==id) {
            flag = true;
          }
        } else {
          if(id == 0) {
            flag = true;
          }
        }
      }
      return flag;
    },
    // 是否为可一键批量生成的类型
    checkAllIn (type) {
      if (type && this.typesAllIn && this.typesAllIn.includes(type)) {
        if (this.dataList[type] && this.dataList[type].length > 1) {
          return true
        }
      }
      return false
    },
    // mob端开合侧边栏
    changeSpread() {
      // console.log(this.isSpread);
      this.isSpread = !this.isSpread;
      if(this.isSpread) {
        $('.page-poster .operation-area .operation-con ul').scrollTop(0);
      }
      this.$forceUpdate();
    },

    // 选择年份
    selectYear(key) {
      let origin = this.selectParams.year;
      let year_new = '';
      for(let y in this.id_arr) {
        if(this.id_arr[y].sid == key) {
          year_new = y;
          break;
        }
      }
      this.selectParams.year = year_new;
      // this.global.year = year_new;
      this.changeSelect('year', [[origin, year_new]] );
    },
    // 选择类型
    selectType(type) {
      let origin = this.selectParams.type;
      // this.selectParams.type = type;
      this.changeSelect('type', [[origin, type]] );
    },
    // 选择专题/课程/案例/日程
    selectId(id, secondary) {
      // console.log('Poster·method·select_id ', id, this.selectParams)
      let origin = '';
      if(this.selectParams.type == 'chairman' || this.selectParams.type == 'producer') {
        origin = this.selectParams.lecturerId;
        // this.selectParams.lecturerId = id;
      }
      if(this.selectParams.type == 'subject') {
        origin = this.selectParams.venueId;
        // this.selectParams.venueId = id;
      }
      if(this.selectParams.type == 'course') {
        origin = this.selectParams.courseId;
        // this.selectParams.courseId = id;
      }
      if(this.selectParams.type == 'schedule') {
        origin = this.selectParams.scheduleId;
        // this.selectParams.scheduleId = id;
      }
      // key
      let sKey = this.selectParams.type && this.secondaryKeys && this.secondaryKeys[this.selectParams.type]
      let secondaryOption = []
      if (sKey && secondary && secondary[sKey]) {
        if (this.selectParams[sKey] !== secondary[sKey]) {
          secondaryOption = [{sKey: this.selectParams[sKey]||''}, secondary]
        }
      }
      this.changeSelect('id', [[origin, id], secondaryOption]);
    },
    // 批量生成
    resetAllIn () {
      this.isAllIn = false
      this.indexsAllIn = []
      this.dataAllIn = {}
      this.paramsAllIn = { sid: false, type: false, cardid: false, id: [], year: false }
    },
    selectAllIn (type, index) {
      // console.log('Poster·method·select_all ', type, index, this.selectParams)
      this.resetAllIn()
      if (type && this.dataList[type]) {
        if (index && index.length > 0) {
          this.indexsAllIn = index
        } else {
          this.indexsAllIn = [0] // [0, this.dataList[type].length]
        }
        this.paramsAllIn = {
          sid: this.selectParams.type || '',
          type: type,
          cardid: this.selectParams.cardId || '',
          id: [],
          year: this.selectParams.year || ''
        }
        let arr = this.indexsAllIn[1] ? this.dataList[type].slice(this.indexsAllIn[0], this.indexsAllIn[1]) : this.dataList[type]
        for (let item of arr) {
          let id = ''
          let sId = ''
          if (this.selectParams.type === 'chairman') {
            id = item.id || ''
          } else if (this.selectParams.type === 'producer') {
            id = item.id || ''
          } else if (this.selectParams.type === 'subject' && item.venueName !== '开幕式') {
            id = item.id || ''
          } else if (this.selectParams.type === 'course') {
            id = item.courseid || item.courseId || item.id || ''
            sId = (item.teacher && item.teacher[0] && item.teacher[0].id) || '' // clid
          }
          if (id) {
            let idKey = id
            if (sId) {
              idKey += '_' + sId
            }
            this.paramsAllIn.id.push(idKey)
            this.dataAllIn[idKey] = item
          }
        }
        // if (this.paramsAllIn.id.length < this.dataList[type].length) {
        //   this.isAllIn = false
        // }
        this.isAllIn = true;
        this.$emit('allin', 1);
        this.$forceUpdate();
      }
    },

    // 选择完成 -> 切换数据/路由跳转
    refreshWin(param) {
      // window.open(`/poster${param}`, '_self'); // 强制刷新-初始化全部数据
      // this.$router.push(`/poster${param}`)
      this.$router.replace(`/poster${param}`)
    },
    changeSelect(_method, options) {
      this.resetAllIn()
      // console.log('Poster·method·change_select ', _old, _new, _method, this._cardids, this.selectParams)
      let _old = (options && options[0] && options[0][0]) || null
      let _new = (options && options[0] && options[0][1]) || null
      let _old_secondary = (options && options[1] && options[1][0]) || null
      let _new_secondary = (options && options[1] && options[1][1]) || {}
      if(_new && !(_old == _new) || (this.global.isObjectKeys(_new_secondary) && !this.global.toCompareObject(_old_secondary, _new_secondary))) {
        this.selectParams.cardDetailId = false;
        /* year/type需要window.open()或$router强制刷新参数 */
        let href = '';
        if(_method === 'year' && (_old != _new)) {
          // this.$router.replace({path:'/poster', query:{sid:id_arr[_new].sid}});
          // href = `?sid=${id_arr[_new].sid}&type=${this.selectParams.type||'subject'}`;
          // this.selectParams.type = 'subject';
          // href = `?sid=${id_arr[_new].sid}&type=subject`;
          href = `?sid=${id_arr[_new].sid}`;
          this.refreshWin(href);
        }
        if(_method === 'type' && (_old != _new)) {
          /* 1、window.open() 强制刷新页面 */
          // href = `?sid=${this.selectParams.sid}&type=${_new}`;
          // if(_new==='case' && this._cardids[this.selectParams.sid] && this._cardids[this.selectParams.sid]['casedetailid']) {
          //   href += `&detailid=${this._cardids[this.selectParams.sid]['casedetailid']}`;
          // }
          // this.refreshWin(href);
          /* 2、$emit+watch使父组件切换type 不用刷新页面 父组件增加处理方法 */
          // this.$emit('typechange', _new);
          /* 3、router+props+watch使父组件切换type 不用刷新页面 父组件原props-watch方法修改即可不必再增加处理方法 */
          // let type_query = { sid: this.selectParams.sid, type: _new, cardid: this._cardids[this.selectParams.sid] && this._cardids[this.selectParams.sid][_new] || '' };
          // if(_new === 'case') {
          //   type_query.detailid = this._cardids[this.selectParams.sid] && this._cardids[this.selectParams.sid]['casedetailid'] || '';
          // }
          // this.$router.replace({ path: '/poster', query: type_query });
          let href = `?sid=${this.selectParams.sid}&type=${_new}&cardid=${(this._cardids[this.selectParams.sid] && this._cardids[this.selectParams.sid][_new]) || ''}`
          if (_new === 'case') {
            href += `&detailid=${(this._cardids[this.selectParams.sid] && this._cardids[this.selectParams.sid]['casedetailid']) || ''}`
          }
          this.refreshWin(href);
        }
        /* subject/course/schedule切换子项(专题/课程/日程)不需要window.open(),通过$emit()切换id参数即可 */
        if(_method === 'id') {
          let idParam = {type:this.selectParams.type, id:_new, ..._new_secondary}
          this.$emit('idchange', idParam);
        }
      }
    },

    // 获取卡片数据 (from mrm)
    getActiveData(_params) {
      // console.log('Poster·method·get_active_data ', _params);
      if(_params.sid && _params.cardId && (_params.type==='schedule' || _params.cardDetailId || _params.courseId || _params.venueId || _params.scheduleId || _params.lecturerId)) {
        let params = `?sid=${_params.sid}&cardId=${_params.cardId}`;
        if (_params.cardDetailId) {
          params += `&cardDetailId=${_params.cardDetailId||''}`;
        } else {
          if (_params.type==='course') {
            params += `&courseId=${_params.courseId||''}`
          } else if (_params.type==='subject') {
            params += `&venueId=${_params.venueId||''}`
          } else if (['chairman','producer'].includes(_params.type)) {
            params += `&lecturerId=${_params.lecturerId||''}`;
          }
        }
        this.$http.jsonp(getCardInfo+params)
        .then(res => {
          return res.json();
        })
        .then(res => {
          // console.log('Poster·method·get_active_data------ ', res);
          if(res && res.status=='200' && res.data) {
            this.dataActive = this.global.deepClone(res.data);
            this.isActive = true;
          }
        })
      }
    },

    setInfo(res) {
      console.log('👌🏻act-poster ', res);
      this.isSpread = false;
      // this.changeSpread();
      // this.$emit('idchange', {type:this.selectParams.type, id:this.selectParams.venueId});
      // 
      // if(res.type && res.data) {
      //   if(res.type == 'subject') {
      //     this.selectParams.venueId = res.data && res.data.venue && res.data.venue.svId || false;
      //   }
      //   if(res.type == 'course') {
      //     this.selectParams.courseId = res.data && res.data.course && (res.data.course.courseId || res.data.course.courseid) || false;
      //   }
      //   if(res.type == 'schedule') {
      //     this.selectParams.scheduleId = res.data && res.data.schedule && (res.data.schedule.id!==false) ? res.data.schedule.id : false; 
      //   }
      // }
    },
    setInvite (res) {
      console.log('invite ', res)
      this.inviteGroup = 1;
      this.inviteFile = {};
      this.inviteText = [];
      if (res && this.global.isObjectKeys(res)) {
        this.inviteFile = res.file || {};
        this.inviteText = res.text || [];
        this.inviteGroup = res.group || 1;
      }
      this.$forceUpdate();
    },




    // 选择完成->格式化参数路由跳转
    _change_Select(_old, _new, _method) {
      if(_method === 'year') {
        this.selectParams.type = 'subject';
      }
      if(_method === 'year' || _method === 'type') {
        this.selectParams.venueId = '';
        this.selectParams.courseId = '';
        this.dataList.active = {};
        this.dataList.origin = {};
      }
      if(!(_old === _new)) {
        let path = this.$route.path;
        let name = this.$route.name;
        let query = {
          sid: this.$route.query && this.$route.query.sid || '',
          type: this.$route.query && this.$route.query.type || this.selectParams.type || '',
          cardid: this.$route.query && this.$route.query.cardid || this.allCardIds[this.selectParams.year][this.selectParams.type] || ''
        };
        // 设置 id 和 detailid
        if(this.selectParams.type=='subject' && this.selectParams.venueId) {
          query.id = this.selectParams.venueId;
        } else if(this.selectParams.type=='course' && this.selectedCourseId) {
          query.id = this.selectedCourseId;
        }
        query.detailid = this.selectParams.type==='case' ? this._cardids[this.selectParams.sid]['casedetailid'] : '';
        // query.detailid = this.selectParams.cardDetailId || '';
        // console.log(path, name, query);
        this.$router.replace({ path, name, query });
      }
    },
    // 获取海报(卡片)数据-包括图片和文字数据
    _set_Info(res) {
      // console.log('👌🏻act ', res, this.dataList);
      if(res.data){
        this.showCard = false;
        this.dataList.active = res.data;
        // this.dataList.origin = {};
        // if(res.type == 'subject') {
        //   this.selectParams.venueId = res.data.venue && res.data.venue.svId || '';
        // }
        // if(res.type == 'course') {
        //   this.selectedCourseId = res.data.course && res.data.course.courseId || '';
        // }
        // if(res.type == 'case') {
        //   this.selectedCaseId = res.data.case && Object.keys(res.data.case) && Object.keys(res.data.case).join(',') || '';
        // }
        // if(res.type == 'schedule') {
        //   // 
        // }
        this.showCard = true;
      }
      this.$forceUpdate();
    },
  }
}
</script>
